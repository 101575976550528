import { bigInt, email, datetime } from "./types.js";
import { ROLE_SYSTEM, ROLE_STAFF, ROLE_STUDENT, PRIVILEGES, PROFICIENCIES } from "@traverse/consts";
import _ from "lodash";

export const roleId = { type: "integer", enum: [ROLE_SYSTEM, ROLE_STAFF, ROLE_STUDENT] };

const proficiencyId = {
  ...bigInt,
  enum: PROFICIENCIES.map((po) => po.id)
};

export const adminUserPreferencesDefaults = {
  theme: "default",
  imagesLongTextAlts: false,
  audioDescriptionPause: false,
  audioDescriptionRate: 1,
  audioDescriptionVolume: 1,
  useBrowserSpeech: false,
  assignmentStudentNicknames: false,
  allowGoogleDocs: false,
  videoRecordingCountdown: false,
  gradeColors: {
    defaultColor: 8,
    segmentColors: [
      { begin: 0, colorId: 5, end: 59 },
      { begin: 60, colorId: 3, end: 79 },
      { begin: 80, colorId: 6, end: 100 }
    ]
  },
  studentNotifs: false,
  teacherNotifs: false,
  systemNotifs: false
};

export const studentUserPreferencesDefaults = {
  theme: "default",
  imagesLongTextAlts: false,
  audioDescriptionPause: false,
  audioDescriptionRate: 1,
  audioDescriptionVolume: 1,
  useBrowserSpeech: false
};

export const userPrivilege = {
  type: "object",
  properties: {
    id: bigInt,
    userId: bigInt,
    privilege: {
      type: "string",
      enum: _.map(PRIVILEGES, "id")
    },
    config: {
      type: "object",
      nullable: true
    },
    createdById: bigInt,
    modifiedById: bigInt,
    dateModified: datetime,
    dateCreated: datetime
  },
  required: ["privilege", "userId"],
  // Privs are defined in consts file, with expected user role and config
  // schema -- generate the required allOf by mapping over defined privs
  allOf: PRIVILEGES.map(({ id, config, roleId }) => {
    const properties = _.mapValues(config ?? {}, (val) => {
      if (val === "id") {
        return bigInt;
      } else if (val === "boolean") {
        return { type: "boolean" };
      }
    });

    const hasProperties = _.size(properties) > 0;

    return {
      if: {
        properties: {
          privilege: {
            type: "string",
            const: id
          }
        },
        required: ["privilege"]
      },
      then: {
        type: "object",
        properties: {
          userRoleId: { type: "integer", const: roleId },
          config: {
            type: "object",
            properties: properties,
            required: Object.keys(properties),
            nullable: !hasProperties
          }
        },
        required: ["userRoleId", ...(hasProperties ? ["config"] : [])]
      }
    };
  })
};

export const adminUserPreferences = {
  type: "object",
  properties: {
    assignmentStudentNicknames: {
      type: "boolean"
    },
    allowGoogleDocs: {
      type: "boolean"
    },
    videoRecordingCountdown: {
      type: "boolean"
    },
    gradeColors: {
      type: "object",
      properties: {
        defaultColor: {
          type: "integer"
        },
        segmentColors: {
          type: "array"
        }
      }
    },
    theme: {
      type: "string"
    },
    imagesLongTextAlts: {
      type: "boolean"
    },
    audioDescriptionPause: {
      type: "boolean"
    },
    audioDescriptionRate: {
      type: "number"
    },
    audioDescriptionVolume: {
      type: "number"
    },
    useBrowserSpeech: {
      type: "boolean"
    },
    studentNotifs: {
      type: "boolean"
    },
    teacherNotifs: {
      type: "boolean"
    },
    systemNotifs: {
      type: "boolean"
    },
    demoLevel: {
      type: "number"
    }
  }
};
const requiredAdminUserPrefs = [
  "assignmentStudentNicknames",
  "allowGoogleDocs",
  "videoRecordingCountdown",
  "gradeColors",
  "theme",
  "imagesLongTextAlts",
  "audioDescriptionPause",
  "audioDescriptionRate",
  "audioDescriptionVolume",
  "useBrowserSpeech",
  "studentNotifs",
  "teacherNotifs",
  "systemNotifs"
];

export const studentUserPreferences = {
  type: "object",
  properties: {
    theme: {
      type: "string"
    },
    imagesLongTextAlts: {
      type: "boolean"
    },
    audioDescriptionPause: {
      type: "boolean"
    },
    audioDescriptionRate: {
      type: "number"
    },
    audioDescriptionVolume: {
      type: "number"
    },
    useBrowserSpeech: {
      type: "boolean"
    }
  }
};

export const user = {
  allOf: [
    {
      type: "object",
      properties: {
        id: bigInt,
        firstName: {
          type: "string"
        },
        lastName: {
          type: "string"
        },
        roleId: roleId,
        lastOnline: datetime,
        openAssignments: bigInt,
        nextDueDate: datetime,
        groupIds: {
          type: "array",
          items: bigInt,
          nullable: true
        },
        teacherIds: {
          type: "array",
          items: bigInt,
          nullable: true
        }
      },
      required: ["id", "firstName", "lastName", "roleId"]
    },
    {
      oneOf: [
        {
          type: "object",
          properties: {
            roleId: { type: "integer", const: ROLE_STUDENT },
            proficiencyId: proficiencyId,
            preferences: {
              ...studentUserPreferences,
              required: Object.keys(studentUserPreferences.properties),
              additionalProperties: false
            }
          }
        },
        {
          type: "object",
          properties: {
            roleId: { type: "integer", const: ROLE_STAFF },
            preferences: {
              ...adminUserPreferences,
              required: requiredAdminUserPrefs,
              additionalProperties: false
            }
          }
        },
        {
          type: "object",
          properties: {
            roleId: { type: "integer", const: ROLE_SYSTEM },
            loginDisabled: { type: "boolean", nullable: true },
            preferences: {
              ...adminUserPreferences,
              required: requiredAdminUserPrefs,
              additionalProperties: false
            }
          }
        }
      ]
    }
  ]
};

export const userPostBody = {
  allOf: [
    // Must have common user attributes
    {
      type: "object",
      properties: {
        firstName: {
          type: "string"
        },
        lastName: {
          type: "string"
        },
        username: {
          type: "string"
        },
        roleId: roleId,
        email: email,
        proficiencyId: proficiencyId
      },
      required: ["firstName", "lastName", "username", "roleId"]
    },
    // Must have one of the user creation types: password vs sendInvite
    {
      oneOf: [
        { type: "object", properties: { password: { type: "string" } }, required: ["password"] },
        {
          type: "object",
          properties: { email: email, sendInvite: { type: "boolean", const: true } },
          required: ["sendInvite", "email"]
        }
      ]
    },
    // Must have school/district configuration if staff or student
    {
      oneOf: [
        {
          type: "object",
          properties: {
            roleId: { type: "integer", enum: [ROLE_STAFF, ROLE_STUDENT] },
            schoolIds: {
              type: "array",
              items: bigInt
            },
            districtId: bigInt,
            userPrivileges: {
              type: "array",
              items: userPrivilege
            }
          },
          required: ["districtId", "roleId"]
        },
        {
          type: "object",
          properties: {
            roleId: { type: "integer", enum: [ROLE_SYSTEM] },
            userPrivileges: {
              type: "array",
              items: userPrivilege
            }
          },
          required: ["roleId"]
        }
      ]
    }
  ]
};

export const userPatchBody = {
  allOf: [
    {
      type: "object",
      properties: {
        firstName: {
          type: "string"
        },
        lastName: {
          type: "string"
        },
        password: {
          type: "string"
        },
        currentPassword: {
          type: "string"
        },
        roleId: roleId
      },
      required: ["roleId"]
    },
    {
      oneOf: [
        {
          type: "object",
          properties: {
            roleId: { type: "integer", const: ROLE_STUDENT },
            proficiencyId: proficiencyId,
            preferences: {
              ...studentUserPreferences,
              required: Object.keys(studentUserPreferences.properties),
              additionalProperties: false
            }
          }
        },
        {
          type: "object",
          properties: {
            roleId: { type: "integer", const: ROLE_STAFF },
            preferences: {
              ...adminUserPreferences,
              required: requiredAdminUserPrefs,
              additionalProperties: false
            },
            userPrivileges: {
              type: "array",
              items: userPrivilege
            }
          }
        },
        {
          type: "object",
          properties: {
            roleId: { type: "integer", const: ROLE_SYSTEM },
            loginDisabled: { type: "boolean", nullable: true },
            userPrivileges: {
              type: "array",
              items: userPrivilege
            },
            preferences: {
              ...adminUserPreferences,
              required: requiredAdminUserPrefs,
              additionalProperties: false
            }
          }
        }
      ]
    }
  ]
};

export const userGetResponse = user;

export const userGetManyResponse = {
  type: "array",
  items: user
};
